<template>
  <div class="suspended width100">
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="mx-auto d-block" src="@/assets/icon/mail-confirm.svg" alt="suspended" />
      <div class="f28 font-weight-bold primary--text text--darken-2 text-center mt-5">Confirm your email</div>
      <div class="f18 font-weight-medium text--text text-center">Check your inbox for the email confirmation link</div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  methods: {
    back() {
      this.$router.push('/sign-in');
    },
  },
};
</script>
